/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { initializeAnimation } from './global-animation';
import { getImagePath } from './util';

function decorateInputs(form) {
  const inputs = form.querySelectorAll('input,textarea');
  if (!inputs.length) return;

  function onKeydown(evt) {
    if (evt.key === 'Enter') {
      nextStep(mktForm, form, formId, evt);
    }
  }

  function onKeyup(evt) {
    const { srcElement } = evt;
    const { value, parentNode } = srcElement;
    if (value) parentNode.classList.add('has-value');
    else parentNode.classList.remove('has-value');
  }

  inputs.forEach((el) => {
    el.addEventListener('keyup', onKeyup);
    el.addEventListener('keydown', onKeydown);
  });

  const selects = form.querySelectorAll('select');

  selects.forEach((el) => {
    const { parentNode } = el;
    parentNode.classList.add('has-value');
  });

  const checkboxes = form.querySelectorAll('input[type="checkbox"]');
  
  checkboxes.forEach((el) => {
    el.closest('.mktoFieldWrap').classList.add('mktoCheckbox');
  })

  var formEl = form, //.getFormElem()[0],
    targetWrapper = formEl.closest('[data-fields]'),
    targetData,
    placeholders = formEl.querySelectorAll('.mktoPlaceholder');

  if(targetWrapper){
    targetData = targetWrapper.getAttribute('data-fields').split(',').filter(element => element);

    if (targetData.length > 0) {
      var moveArray = [];

      var formRows = formEl.querySelectorAll('.mktoFormRow'),
        buttonRow = formEl.querySelector('.mktoButtonRow');

      // tag each field row/wrapper with the name of its first input to make it easily seekable
      for (var i = 0, imax = formRows.length, wrappedField; i < imax; i++) {
        if (wrappedField = formRows[i].querySelector('INPUT[name],SELECT[name],TEXTAREA[name],.mktoPlaceholder')) {
          var targetName = wrappedField.classList.contains('mktoPlaceholder') ? 'placeholder' : wrappedField.name,
            checkClass;

          formRows[i].setAttribute('data-wrapper-for', targetName);

          if(targetName == 'placeholder'){
            wrappedField.classList.forEach((cls) => {
              checkClass = cls.replace('mktoPlaceholder','');
              if(checkClass.length > 0)
                targetName = checkClass;
            })
          }

          if (targetData.includes(targetName)) {
            moveArray.push(formRows[i]);
          }
        }
      }

      // setTimeout(function() {
      moveArray.forEach((el) => {
        formEl.insertBefore(el, buttonRow);
      })
      // }, 200);
    }
  }
}

function decorateRows(container, current = 0) {
  const rows = container.querySelectorAll('.mktoFormRow');
  rows.forEach((row) => {
    row.classList.remove('not-a-marketo-field');
    row.classList.remove('marketo-field');
    row.classList.remove('marketo-submenu-field');
    row.classList.remove('marketo-logic-field');
  });

  rows.forEach((row) => {
    if (!row.querySelector('.mktoFieldDescriptor')) {
      row.classList.add('not-a-marketo-field');
    } else if (
      row.querySelector(
        '.mktoFieldDescriptor[type=hidden][name=mktoUTMContent]'
      ) ||
      row.querySelector(
        '.mktoFieldDescriptor[type=hidden][name=mktoUTMContent]'
      )
    ) {
      row.classList.add('not-a-marketo-field');
    } else if (row.querySelector('.mktoLogicalField')) {
      row.classList.add('marketo-field');
      row.classList.add('marketo-logic-field');
    } else if (row.querySelector('.mktoFieldDescriptor[type=hidden]')) {
      row.classList.add('marketo-submenu-field');
      row.classList.add('not-a-marketo-field');
    } else {
      row.classList.add('marketo-field');
    }
  });

  const fields = container.querySelectorAll(
    '.mktoFormRow:not(.not-a-marketo-field)'
  );

  fields.forEach((field) => {
    field.classList.add('hidden');
  });

  fields[current].classList.remove('hidden');

  return fields;
}

export function handleLogicalField(inputBef, inputFields, step) {
  if (
    inputBef.attributes.getNamedItem('type')?.value === 'checkbox' &&
    inputBef.parentElement.classList.contains('mktoLogicalField') &&
    inputBef.checked
  ) {
    const nextFields = inputFields.slice(step);
    nextFields.some((field) => {
      const isSubmenuField = field.classList.contains('marketo-submenu-field');
      if (isSubmenuField) {
        field.classList.remove('not-a-marketo-field');
        field.classList.add('hidden');
      }
      return !isSubmenuField;
    });
  }
}

export async function nextStep(mktForm, form, formId, evt) {
  evt.stopPropagation();
  evt.preventDefault();

  const multiStepFormContainer = form.parentElement;

  const stepEl = multiStepFormContainer.querySelector('.marketo-stepper-step');
  // eslint-disable-next-line radix
  const step = parseInt(stepEl.innerText);

  const counterEl = multiStepFormContainer.querySelector(
    '.marketo-stepper-counter'
  );

  // eslint-disable-next-line radix
  const total = parseInt(counterEl.innerText);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(total)) return;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(step)) return;
  // eslint-disable-next-line no-undef

  if (step < total) {
    decorateRows(form, step - 1);
    decorateInputs(form);
    let fields = multiStepFormContainer.querySelectorAll(
      '.mktoFormRow:not(.not-a-marketo-field)'
    );

    const fieldBef = fields[step - 1];
    const inputBef = fieldBef.querySelector(
      'input, select, textarea, checkbox'
    );

    inputBef.focus();
    inputBef.blur();
    inputBef.focus();
    inputBef.blur();

    handleLogicalField(inputBef, fields, step);

    fields = multiStepFormContainer.querySelectorAll(
      '.mktoFormRow:not(.not-a-marketo-field)'
    );
    counterEl.innerHTML = fields.length;

    const hasErrorEl = fieldBef.querySelector('.mktoError');

    if (hasErrorEl && hasErrorEl.style.display !== 'none') return;

    fieldBef.classList.add('hidden');
    fields[step].classList.remove('hidden');
    fields[step].querySelector('input, select, textarea').focus();
    stepEl.innerText = step + 1;
  } else {
    mktForm.submit();
  }
}

export function createStepper(
  mktForm,
  form,
  formId,
  target = document,
  counter = 1
) {
  const el = target.querySelector('form');
  const stepperDiv = document.createElement('div');
  stepperDiv.setAttribute('class', 'marketo-stepper-container');

  const stepDiv = document.createElement('div');
  stepDiv.setAttribute('class', 'marketo-stepper-step');
  stepDiv.innerHTML = '1';

  const counterDiv = document.createElement('div');
  counterDiv.setAttribute('class', 'marketo-stepper-counter');
  counterDiv.innerHTML = counter;

  if (counter === 1)
    stepperDiv.classList.add('marketo-stepper-container--hidden');

  stepperDiv.appendChild(stepDiv);
  stepperDiv.appendChild(counterDiv);
  el.appendChild(stepperDiv);
}

export function createControls(mktForm, form, formId, target = document) {
  const el = target.querySelector('form');
  const controlsDiv = document.createElement('div');
  controlsDiv.setAttribute('class', 'marketo-controls-container');

  const btn = document.createElement('button');
  btn.setAttribute('class', 'marketo-controls-button');

  const img = document.createElement('img');
  img.setAttribute('src', getImagePath('/images/arrow-white.svg'));

  btn.addEventListener('click', (e) => nextStep(mktForm, form, formId, e));

  btn.appendChild(img);
  controlsDiv.appendChild(btn);
  el.appendChild(controlsDiv);
}

export function initializeMarketoMultiStepForm(mktForm, form, formId) {
  const el = form.parentElement;
  let shouldMakeMultistep = el.classList.contains('multi-step-form');
  if (!shouldMakeMultistep) return;

  try {
    const buttonContainer = el.querySelector('.mktoButtonRow');
    if (!buttonContainer) return;

    const rows = el.querySelectorAll('.mktoFormRow');
    rows.forEach((row) => {
      if (!row.querySelector('div.mktoFieldDescriptor')) {
        row.classList.add('not-a-marketo-field');
      } else {
        row.classList.add('marketo-field');
      }
    });

    const fields = el.querySelectorAll(
      '.mktoFormRow:not(.not-a-marketo-field)'
    );

    fields.forEach((field, i) => {
      if (i && i > 0) field.classList.add('hidden');
    });

    buttonContainer.classList.add('hidden');

    createStepper(mktForm, form, formId, el, fields.length);
    createControls(mktForm, form, formId, el);
  } catch (error) {
    console.error('failed to start multistep for marketo', error);
  }
}

export async function loadMarketoScripts(url) {
  const check = document.querySelector('link#mktoForms2BaseStyle');
  if (check) return true;
  const head = document.getElementsByTagName('head')[0];

  const link = document.createElement('link');
  link.id = 'mktoForms2BaseStyle';
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = `${url}/js/forms2/css/forms2.css`;
  head.appendChild(link);

  const link2 = document.createElement('link');
  link2.id = 'mktoForms2BaseStyle';
  link2.rel = 'stylesheet';
  link2.type = 'text/css';
  link2.href = `${url}/js/forms2/css/forms2-theme-simple.css`;
  head.appendChild(link2);

  const script = document.createElement('script');
  script.src = `${url}/js/forms2/js/forms2.min.js`;
  head.appendChild(script);

  // setInterval is the most secure way to check if a script has been loaded,
  // onload and addeventlisteners do not work all the times and across multiple navigators
  // - artur
  return new Promise((resolve) => {
    let interval = setInterval(() => {
      try {
        if (MktoForms2) {
          clearInterval(interval);
          const event = new CustomEvent('marketo-script-loaded');
          document.dispatchEvent(event);
          resolve(true);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }, 100);
  });
}

export function onFormLoad(mktForm, form, formId) {
  initializeMarketoMultiStepForm(mktForm, form, formId);
  decorateInputs(form);

  const event = new CustomEvent(`marketo-form-loaded-${formId}`);
  document.dispatchEvent(event);
}

export function loadForm(url, accountId, el, formId) {
  return new Promise((resolve) => {
    MktoForms2.loadForm(url, accountId, formId, (mktForm) => {
      onFormLoad(mktForm, el, formId);
      resolve(mktForm);
    });
  });
}

export async function initializeMarketoBase() {
  const marketoForms = document.querySelectorAll('[data-marketo-url]');
  if (!marketoForms || !marketoForms.length) return;

  for (let index = 0; index < marketoForms.length; index += 1) {
    const el = marketoForms[index];

    const {
      marketoUrl: url,
      marketoAccountId: accountId,
      marketoFormId: formId,
      forceLoad,
    } = el.dataset;
    const loaded = !!document.querySelector(
      `[data-marketo-form-id="${formId}"][data-loaded="true"]`
    );
    if (loaded && (!forceLoad || forceLoad !== 'true')) {
      console.error(
        'You tried to load 2 marketoForms with the same ID, we can not do it without lose control of then, form id:',
        formId
      );
      // eslint-disable-next-line no-continue
      continue;
    }
    el.id = `mktoForm_${formId}`;
    el.dataset.loaded = 'true';
    el.dataset.animation = false;
    initializeAnimation(el);

    // eslint-disable-next-line no-await-in-loop
    await loadMarketoScripts(url);
    await loadForm(url, accountId, el, formId);
    el.id = ``;
  }
}
